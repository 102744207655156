@font-face {
  font-family: 'web-icons';
  src:  url('../../fonts/web-icons.eot?qmh4tl');
  src:  url('../../fonts/web-icons.eot?qmh4tl#iefix') format('embedded-opentype'),
    url('../../fonts/web-icons.ttf?qmh4tl') format('truetype'),
    url('../../fonts/web-icons.woff?qmh4tl') format('woff'),
    url('../../fonts/web-icons.svg?qmh4tl#web-icons') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'web-icons' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-user-times:before {
  content: "\e900";
}
.icon-sucess:before {
  content: "\e901";
}
.icon-search:before {
  content: "\e902";
}
.icon-uniE903:before {
  content: "\e903";
}
.icon-uniE904:before {
  content: "\e904";
}
.icon-uniE905:before {
  content: "\e905";
}
.icon-edit:before {
  content: "\e906";
}
.icon-error:before {
  content: "\e907";
}
.icon-exclamation:before {
  content: "\e908";
}
.icon-filter:before {
  content: "\e909";
}
