html,
body,
#root {
    width: 100%;
}

.body {
    font-family: Roboto;
    background-color: white;
    overflow: auto;
    position: absolute;
    top: 50px;
    bottom: 0;
    width: 100%;
}

.body .logoContainer {
    text-align: center;
    margin-bottom: 50px;
}

.body .logo {
    width: 200px;
    height: 200px;
}

.body .title {
    font-size: 21pt;
    font-weight: 500;
    text-align: center;
    margin-bottom: 50px;
}

.body .bullets {
    font-weight: 400;
    font-size: 16pt;
    margin-left: 10%;
    margin-right: 10%;
}

.body .bullets li {
    list-style: decimal;
    margin-bottom: 25px;
}
