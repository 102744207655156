.login-card-container {
    margin-left: auto;
    margin-right: auto;
    padding-top: 177px;
    max-width: 668px;
}

.login-card .card-body {
    padding: 2rem 1.5rem !important ;
}

.logo {
    max-width: 251px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 31px;
}
.logo img {
    width: 100%;
}
a {
    cursor: pointer;
}

.backdrop {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;
}

.backdrop .top {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 346px;
    background-color: #091668;
}

.card-footer {
    position: relative;
    background-color: transparent !important;
    border: none !important;
    height: 0;
    padding: 0 0 0 0 !important;
}

.card-footer .footer {
    position: absolute;
    top: 40px;
    right: 0;
    text-align: right;

    /*font-family: Avenir;*/
    font-size: 18px;
    color: #7b7b7b;
    font-weight: 400;
}

.theos {
    color: #2f89cd;
}

.health {
    color: #717171;
    /*font-weight: 900;*/
}

.login-body {
    margin-left: 50px;
    margin-right: 50px;
    padding: 0 50px 50px 50px;
}

.MuiTextField-root {
    width: 100%;
}

.tbd svg {
    color: #c6c6c6;
    margin-right: 15px;
}
svg.icon-red-color {
    color: #E02020;
    margin-right: 15px;
}

.satisfied svg {
    color: green;
    margin-right: 15px;
}
.input-alert-error .alert {
    padding: 0rem; background-color: transparent; color: #f44336; border: 0;
}
.cnf-reset-error-msg{
    position: relative; top: 6px;
}
.set-password-instruction {
    color: #383838; line-height: 1.4;
}
.set-password-details span:first-child { min-width: 109px; margin-right: 5px;}
.set-password-details { margin-bottom: 40px;}
.set-password-details-items:not(:last-child) { margin-bottom: 14px; }
@media only screen and (max-width: 767px) {
    .logo { max-width: 180px; }
    .login-body {
        margin-left: 50px;
        margin-right: 50px;
        padding: 0 0 16px 0;
    } 
}

@media only screen and (max-width: 575px) {
   
    .login-body {
        margin-left: 0px;
        margin-right: 0px;
        padding: 0 0 16px 0;
    } 
}