.header-logo img {
    max-width: 123px;
    width: auto;
    max-height: 45px;
    height: auto;
}
@media(max-width: 991px){
    .header-logo img{
        max-width: 95px;
    } 
}
@media(max-width: 380px){
    .header-logo img{
        max-width: 82px;
    }
}

.login-card-container .logo img, .thankyou-page-wrapper img{
    max-height: 150px;
    width: auto;
}